import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Container from '../components/Container'
import About from '../components/About'

class Ofirmie extends React.Component {
  render() {
    const siteTitle = 'O firmie'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} keywords={[`Lancet`, `ostrzenie`, `Kraków`]} />
        <section className="section is-midle">
          <About />
        </section>
      </Layout>
    )
  }
}

export default Ofirmie
